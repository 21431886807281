export const exclusions = [
  'etc.',
  'e.g.',
  'i.e.',
  '@',
  'gmail.com',
  'hotmail.com',
]

// Check if the message should be excluded from processing
export const isExclusion = (text) => {
  return exclusions.some((ex) => text.toLowerCase().includes(ex.toLowerCase()))
}

//Process incoming chunk of meesage
export const processChunk = async (
  chunkMesage,
  sentenceBuffer,
  handleSpeakRef,
) => {
  sentenceBuffer += chunkMesage
  const trimmedBuffer = sentenceBuffer.trim()
  const lastChar = trimmedBuffer.slice(-1)

  if (['.', '?', '!'].includes(lastChar) && !isExclusion(trimmedBuffer)) {
    if (handleSpeakRef.current) {
      await handleSpeakRef.current(trimmedBuffer)
    }

    return ''
  }
  return sentenceBuffer
}

//Handle the final message when the stream ends
export const finalizeConversation = (
  dispatch,
  updatedConversation,
  fullResponse,
) => {
  const newAPiMessage = {
    sender_type: 'apiMessage',
    message: fullResponse,
  }

  const finalConversation = [...updatedConversation, newAPiMessage]

  dispatch({ type: 'SET_CONVERSATION', payload: finalConversation })
  dispatch({ type: 'SET_CURRENT_API_MESSAGE_TRANSCRIPT_FULL', payload: null })

  return finalConversation
}
