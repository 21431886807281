import Compressor from 'compressorjs'

export const handleFileUpload = (file, setFileBase64StringAndTempFile) => {
  if (!file) {
    // Clear any existing file information if no file is present
    setFileBase64StringAndTempFile(null, null)
    return
  }

  const processFile = (processedFile) => {
    const reader = new FileReader()
    reader.readAsDataURL(processedFile)
    reader.onload = () => {
      setFileBase64StringAndTempFile(reader.result, processedFile)
    }
    reader.onerror = (error) => {
      console.error('Error reading file:', error)
    }
  }

  if (file.type.startsWith('image/')) {
    new Compressor(file, {
      quality: 0.6, // Reduce image quality to 60%
      success(compressedResult) {
        processFile(compressedResult)
      },
      error(err) {
        console.error('Compression error:', err.message)
      },
    })
  } else {
    processFile(file) // Handles PDF and text/plain files directly
  }
}

export const splitter = (string, splitterValue, returnValueIndex) => {
  const words = string.split(splitterValue)
  return words[returnValueIndex]
}

export const formatDate = () => {
  const today = new Date()
  const day = today.getDate().toString().padStart(2, '0')
  const month = (today.getMonth() + 1).toString().padStart(2, '0')
  const year = today.getFullYear()

  return `${day}/${month}/${year}`
}
