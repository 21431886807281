import styles from '../../styles/CourseSlider.module.css'
import { ChevronLeft, ChevronRight, AlertTriangle } from 'lucide-react'
import { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'

const CourseSlider = ({ courseData }) => {
  const courseImageSample =
    process.env.PUBLIC_URL + '/images/testSlider/brain.png'

  const endOfModule =
    process.env.PUBLIC_URL + '/images/testSlider/endModule.png'
  const sm1ImageSample = process.env.PUBLIC_URL + '/images/testSlider/SM1.png'
  const sm2ImageSample = process.env.PUBLIC_URL + '/images/testSlider/SM2.png'
  const sm3ImageSample = process.env.PUBLIC_URL + '/images/testSlider/SM3.png'
  const books = process.env.PUBLIC_URL + '/images/testSlider/books.png'

  const sm1EndImageSample =
    process.env.PUBLIC_URL + '/images/testSlider/endSM1.png'
  const sm2EndImageSample =
    process.env.PUBLIC_URL + '/images/testSlider/endSM2.png'
  const sm3EndImageSample =
    process.env.PUBLIC_URL + '/images/testSlider/endSM3.png'

  const SUBMODULE_IMAGES = [sm1ImageSample, sm2ImageSample, sm3ImageSample]
  const SUBMODULE_END_IMAGES = [
    sm1EndImageSample,
    sm2EndImageSample,
    sm3EndImageSample,
  ]

  const [numberOfCards, setTotalNumberOfCards] = useState(0)
  const [currentIndexCard, setCurrentIndexCard] = useState(0)
  const [courseDataStructure, setCourseDataStructure] = useState([])
  const [isAnimating, setIsAnimating] = useState(false)

  const [selectedAnswers, setSelectedAnswers] = useState([])

  const [animationClass, setAnimationClass] = useState('entering')

  const generateCourseStructure = (data) => {
    const VIDEO_URLS = [data.video_1_url, data.video_2_url, data.video_3_url]

    const structure = []

    // Add course title
    structure.push({
      type: 'courseTitle',
      title: data.courseJSON.course.title,
      image: courseImageSample,
    })

    // Add description
    structure.push({
      type: 'description',
      title: '📃Description:',
      text: data.courseJSON.course.description,
    })

    // Add learning outcomes
    structure.push({
      type: 'learningOutcomes',
      title: '📖Learning Outcomes:',
      text: data.courseJSON.course.learningOutcomes,
    })

    // Generate submodules
    data.courseJSON.course.modules.forEach((module, index) => {
      // Add submodule title
      structure.push({
        type: 'submoduleTitle',
        image: SUBMODULE_IMAGES[index],
        title: module.title,
        image2: books,
      })

      // Add video
      structure.push({
        type: 'video',
        video:
          index === 0
            ? VIDEO_URLS[0]
            : index === 1
            ? VIDEO_URLS[1]
            : index === 2
            ? VIDEO_URLS[2]
            : null,
      })

      // Add theory snippets
      module.theorySnippets.forEach((theory) => {
        structure.push({
          type: 'theory',
          title: theory.header,
          text: theory.body,
        })
      })

      // Add quiz
      module.quizzes.forEach((quiz) => {
        structure.push({
          type: 'quiz',
          title: 'Module Quiz',
          question: quiz.question,
          answers: quiz.answers.map((answer) => answer),
          smNo: index + 1,
        })
      })

      // Add end of submodule
      structure.push({
        type: 'endOfSubmodule',
        title: `Congratulations!`,
        text: `You have completed submodule ${index + 1}!`,
        image: SUBMODULE_END_IMAGES[index],
      })
    })

    // Add end of module
    structure.push({
      type: 'endModule',
      image: endOfModule,
      title: 'Congratulations! ',
      text: 'You have completed the course.',
    })
    console.log('The structure is: ', structure)

    return structure
  }
  useEffect(() => {
    if (courseData) {
      console.log('Course Data:', courseData) // Check if data is correct
      const courseStructure = generateCourseStructure(courseData)
      setCourseDataStructure(courseStructure)
      console.log('Generated Course Structure:', courseStructure) // Check output
    }
  }, [courseData])

  const goToNextCard = () => {
    if (isAnimating) return
    setIsAnimating(true)

    setAnimationClass('exiting') // Start exit animation
    setTimeout(() => {
      setCurrentIndexCard(
        (prevIndex) => (prevIndex + 1) % courseDataStructure.length,
      )
      setAnimationClass('entering') // Start enter animation
      setIsAnimating(false)
    }, 300) // Match animation duration
  }

  const goToPreviousCard = () => {
    if (isAnimating) return
    setIsAnimating(true)

    setAnimationClass('exiting') // Start exit animation
    setTimeout(() => {
      setCurrentIndexCard((prevIndex) =>
        prevIndex === 0 ? courseDataStructure.length - 1 : prevIndex - 1,
      )
      setAnimationClass('entering') // Start enter animation
      setIsAnimating(false)
    }, 300) // Match animation duration
  }

  const handleAnswerClick = (smNo, index) => {
    setSelectedAnswers((prev) => ({
      ...prev,
      [smNo - 1]: index,
    }))
  }

  const renderCard = (index) => {
    console.log('Index is: ', index)
    console.log('Course is: ', courseDataStructure)
    if (!courseDataStructure) return null
    const card = courseDataStructure[index]
    console.log('Card found is: ', card)

    if (card) {
      switch (card.type) {
        case 'courseTitle':
          return <ModuleTitleCard title={card.title} image={card.image} />

        case 'description':
          return <ModuleDescriptionCard title={card.title} text={card.text} />

        case 'learningOutcomes':
          return <ModuleLearningOutcomesCard text={card.text} />

        case 'submoduleTitle':
          return (
            <SubmoduleTitleCard
              title={card.title}
              image={card.image}
              image2={card.image2}
            />
          )

        case 'video':
          return <SubmoduleVideoCard videoSrc={card.video} />

        case 'theory':
          return <SubmoduleTheoryCard title={card.title} text={card.text} />

        case 'quiz':
          return (
            <SubmoduleQuizCard
              answers={card.answers}
              question={card.question}
              smNo={card.smNo}
              selectedAnswers={selectedAnswers}
              handleAnswerClick={(index) => handleAnswerClick(card.smNo, index)}
            />
          )

        case 'endOfSubmodule':
          return (
            <SobmoduleEndCard
              title={card.title}
              text={card.text}
              image={card.image}
            />
          )
        case 'endModule':
          return (
            <ModuleEndCard
              title={card.title}
              text={card.text}
              image={card.image}
            />
          )

        default:
          return 'Card not found'
      }
    }
  }

  return (
    <div className={styles.sliderContainer}>
      <div
        onClick={goToPreviousCard}
        className={styles.prevBtn}
        disabled={isAnimating}
      >
        <ChevronLeft className={styles.chevron} />
      </div>

      <div className={styles.sliderWrapper}>
        <div className={`${styles.cardContainer} ${animationClass}`}>
          {renderCard(currentIndexCard)}
        </div>
      </div>
      <div
        onClick={goToNextCard}
        className={styles.nextBtn}
        disabled={isAnimating}
      >
        <ChevronRight className={styles.chevron} />
      </div>
    </div>
  )
}
export default CourseSlider

const ModuleTitleCard = ({ title, image }) => (
  <div className={styles.card}>
    <div className={styles.cardCourseTitle}>
      <p className={styles.cardCourseTitleText}>{title}</p>
      <img src={image} alt="sample " className={styles.cardCourseTitleImage} />
    </div>
  </div>
)
const ModuleDescriptionCard = ({ title, text }) => (
  <div className={styles.card}>
    <div className={styles.cardCourseDescription}>
      <p className={styles.cardCourseDescriptionTitleText}>{title}</p>
      <p className={styles.cardCourseDescriptionText}>{text}</p>
    </div>
  </div>
)
const ModuleLearningOutcomesCard = ({ text }) => (
  <div className={styles.card}>
    <div className={styles.cardCourseLearningOutcomes}>
      <p className={styles.cardCourseLearningOutcomesTitleText}>
        📖Learning Outcomes:
      </p>
      <ReactMarkdown className={styles.cardCourseLearningOutcomesText}>
        {text}
      </ReactMarkdown>
    </div>
  </div>
)
const SubmoduleTitleCard = ({ title, image, image2 }) => (
  <div className={styles.card}>
    <div className={styles.cardSMTitle}>
      <img src={image} alt="sample " className={styles.cardSMTitleImage} />
      <p className={styles.cardSMTitleText}>{title}</p>
      <img src={image2} alt="sample " className={styles.cardSMTitleImage} />
    </div>
  </div>
)
const SubmoduleVideoCard = ({ videoSrc }) => (
  <div className={styles.card} style={{ padding: '0px' }}>
    <video controls src={videoSrc} className={styles.cardVideo} />
  </div>
)
const SubmoduleTheoryCard = ({ title, text }) => (
  <div className={styles.card}>
    <div className={styles.cardCourseTheory}>
      <p className={styles.cardCourseTheoryTitleText}>{`💡${title}`}</p>
      <ReactMarkdown className={styles.cardCourseTheoryText}>
        {text}
      </ReactMarkdown>
    </div>
  </div>
)
const SubmoduleQuizCard = ({
  question,
  smNo,
  answers,
  selectedAnswers,
  handleAnswerClick,
}) => (
  <div className={styles.card}>
    <div className={styles.cardQuiz}>
      <div className={styles.cardQuizTop}>
        <p className={styles.cardQuizQuestionNo}>{`🤔Question ${smNo}`}</p>
        <p className={styles.cardQuizQuestionText}>{question}</p>
      </div>
      <div className={styles.cardQuizBottom}>
        {answers.map((answer, index) => (
          <div
            key={index}
            className={
              selectedAnswers[smNo - 1] === index
                ? answer.isCorrect
                  ? styles.correctAnswer
                  : styles.incorrectAnswer
                : styles.answerOption
            }
            onClick={() => handleAnswerClick(index)}
          >
            {answer.text}
            {selectedAnswers[smNo - 1] === index && (
              <span className={styles.feedbackTooltip}>{answer.feedback}</span>
            )}
          </div>
        ))}
      </div>
    </div>
  </div>
)
const SobmoduleEndCard = ({ title, text, image }) => (
  <div
    className={styles.card}
    style={{ padding: '0px', position: 'relative', zIndex: '10' }}
  >
    <img
      src={image}
      alt="End of Submodule"
      className={styles.cardCourseEndOfSMimage}
    />
    <div className={styles.cardCourseEndOfSM}>
      <p className={styles.cardCourseEndOfSMTitle}>{title}</p>
      <p className={styles.cardCourseEndOfSMText}>{text}</p>
    </div>
  </div>
)
const ModuleEndCard = ({ title, text, image }) => (
  <div
    className={styles.card}
    style={{ padding: '0px', position: 'relative', zIndex: '10' }}
  >
    <img
      src={image}
      alt="End of Submodule"
      className={styles.cardCourseEndOfSMimage}
    />
    <div className={styles.cardCourseEndOfSM}>
      <p className={styles.cardCourseEndOfSMTitle}>{title}</p>
      <p className={styles.cardCourseEndOfSMText}>{text}</p>
    </div>
  </div>
)

// const getNumberOfCardsInTotal = (data) => {
//   console.log('Data is: ', data)
//   const numberOfModules = data.courseJSON.course.modules.length
//   const numberOfQuestionsInTotal = data.courseJSON.course.modules.reduce(
//     (acc, currentValue) =>
//       acc + (currentValue.quizzes ? currentValue.quizzes.length : 0),
//     0,
//   )

//   const numberOfTheorySnippets = data.courseJSON.course.modules.flatMap(
//     (module) => module.theorySnippets,
//   ).length
//   console.log('Number of modules is: ', numberOfModules)

//   console.log('Number of questions is: ', numberOfQuestionsInTotal)

//   console.log('Number of theory snippets is: ', numberOfTheorySnippets)

//   const totalNumberOfSlides =
//     1 +
//     1 +
//     1 +
//     3 * numberOfModules +
//     2 * numberOfQuestionsInTotal +
//     numberOfTheorySnippets +
//     1

//   console.log('Number of all cards is : ', totalNumberOfSlides)
//   return totalNumberOfSlides
// }
