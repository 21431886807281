import React, { useEffect, useRef, useState, useCallback } from 'react'
import styles from '../../styles/newChatting/InteractiveAvatar.module.css'
import StreamingAvatar, {
  AvatarQuality,
  StreamingEvents,
  TaskMode,
  TaskType,
  VoiceEmotion,
} from '@heygen/streaming-avatar'

const InteractiveAvatar = ({
  initiateSession,
  avatarId,
  toggleLoadingVIdeo,
  handleMessageFromDeepgram,
  onRegisterHandleSpeak,
}) => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL

  const [connectionId, setConnectionId] = useState(null)

  const [stream, setStream] = useState(null)

  const socketRef = useRef(null)

  const avatar = useRef(null)

  const mediaStream = useRef(null)

  const [accessToken, setAccessToken] = useState('')

  const [data, setData] = useState(null)

  const [isUserTalking, setIsUserTalking] = useState(false)

  const [audioStream, setAudioStream] = useState(null)

  const [isRecording, setIsRecording] = useState(false)

  const [debug, setDebug] = useState('')

  const [error, setError] = useState(null)

  const [isSessionActive, setIsSessionActive] = useState(false) // NEW: Track session state

  const handleSpeak = useCallback(async (text) => {
    if (text && avatar.current) {
      await avatar.current.speak({
        text: text,
        taskType: TaskType.REPEAT,
        taskMode: TaskMode.SYNC,
      })
    }
  }, [])

  // Register handleSpeak with parent when component mounts
  useEffect(() => {
    onRegisterHandleSpeak(handleSpeak)
  }, [handleSpeak, onRegisterHandleSpeak])

  const initiateConversationBackend = useCallback(async () => {
    if (isSessionActive) return

    console.log('Calling start conversation')
    try {
      console.log('Calling start conversation - in the try')
      const response = await fetch(
        `${apiBaseUrl}/video-stream/start-conversation`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            start: true,
          }),
        },
      )

      if (!response.ok) {
        throw new Error('Failed to start conversation')
      }

      const data = await response.json()
      console.log('DATA IS: ', data)
      console.log('I am here in response from backend')
      console.log('The conncetion is is: ', data.connectionId)

      //console.log('I am here in response from backend')
      console.log(data.message)
      setConnectionId(data.connectionId)

      // After successful response, connect to WebSocket
      initializeWebSocket(data.connectionId)
    } catch (error) {
      console.error('Error starting conversation:', error)
      //setError('Failed to start conversation. Please try again.')
      //NOTIFY PARENT
    }
  }, [apiBaseUrl, isSessionActive])

  const initializeWebSocket = useCallback((connectionId) => {
    console.log('Connection in initialize web socket is: ', connectionId)
    if (!connectionId) return
    socketRef.current = new WebSocket(
      `wss://aihub.humanasset.com/ws?connectionId=${connectionId}`,
    )

    socketRef.current.onopen = () => {
      console.log('Connected to server')
    }

    socketRef.current.onmessage = (event) => {
      if (event.data instanceof Blob) {
        console.log('Send to simli audio: ', Date.now().toString())
        event.data.arrayBuffer().then((arrayBuffer) => {
          //const uint8Array = new Uint8Array(arrayBuffer)
          //simliClientRef.current?.sendAudioData(uint8Array)
        })
      } else {
        const message = JSON.parse(event.data)
        console.log('Message from backend is: ', message)
        handleMessageFromDeepgram(message)
        //handleSpeak(message)
      }
    }

    socketRef.current.onerror = (error) => {
      console.error('WebSocket error:', error)
      setError(
        'WebSocket connection error. Please check if the server is running.',
      )
    }
  }, [])

  // const handleSpeak = async (text) => {
  //   if (text.content !== null && text.content !== '') {
  //     await avatar.current.speak({
  //       text: text.content,
  //       taskType: TaskType.REPEAT,
  //       taskMode: TaskMode.SYNC,
  //     })
  //   }
  // }
  const startRecording = async () => {
    console.log('Audio stream session active is: ', isSessionActive)
    if (!isSessionActive || isRecording) return
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
      setAudioStream(stream)
      setIsRecording(true)
      console.log('Audio stream set')
      //const audioData = new Uint8Array(6000).fill(0)
      //simliClientRef.current?.sendAudioData(audioData)
    } catch (err) {
      console.error('Error accessing microphone:', err)
      setError('Error accessing microphone. Please check your permissions.')
    }
  }

  //   async function endSession() {
  //     // Stop all media tracks for the audioStream
  //     if (audioStream) {
  //       console.log('CLOSING ALL STREAMS')
  //       const tracks = audioStream.getTracks() // Get all tracks (audio/video)
  //       tracks.forEach((track) => track.stop()) // Stop each track
  //       console.log('CLOSED ALL AUDIO STREAMS')
  //     }
  //     setStream(undefined)
  //     setAudioStream(null)
  //     setIsRecording(false)
  //     await avatar.current?.stopAvatar()
  //   }

  const endSession = useCallback(async () => {
    console.log('Ending session...')

    if (!isSessionActive) return // Prevent redundant cleanup
    setIsSessionActive(false)

    // 1️⃣ Ensure the avatar session is properly stopped
    if (avatar.current) {
      try {
        await avatar.current.stopAvatar()
        avatar.current = null

        // 💡 Force all WebRTC connections to close
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
          const streams = await navigator.mediaDevices.enumerateDevices()
          streams.forEach((device) => {
            if (device.kind === 'audioinput' || device.kind === 'videoinput') {
              navigator.mediaDevices.getUserMedia({
                audio: false,
                video: false,
              })
            }
          })
        }
      } catch (error) {
        console.error('Error stopping avatar:', error)
      }
    }
    // 2️⃣ Ensure WebSocket is closed properly
    if (socketRef.current) {
      console.log('Closing WebSocket...')
      socketRef.current.onclose = () => console.log('WebSocket closed.')
      socketRef.current.onerror = (error) =>
        console.log('WebSocket error ignored:', error)
      socketRef.current.close()
      socketRef.current = null
    }

    // 3️⃣ Stop audio streams
    if (audioStream) {
      console.log('Stopping audio stream...')
      audioStream.getTracks().forEach((track) => track.stop())
      setAudioStream(null)
    }

    // 4️⃣ Ensure loading state is reset
    toggleLoadingVIdeo(false)
    console.log('Session cleanup complete.')
  }, [isSessionActive, audioStream, toggleLoadingVIdeo])

  async function fetchAccessToken() {
    try {
      const response = await fetch(
        'https://api.heygen.com/v1/streaming.create_token',
        {
          method: 'POST',
          headers: {
            'x-api-key': process.env.REACT_APP_HEYGEN_API_KEY,
          },
        },
      )
      const data = await response.json()

      console.log('Access Token:', data) // Log the token to verify
      setAccessToken(data.data.token)

      return data.data.token
    } catch (error) {
      console.error('Error fetching access token:', error)
    }
  }

  const startHeyGen = async () => {
    if (isSessionActive) return
    const newToken = await fetchAccessToken()

    console.log('New token is: ', newToken)

    avatar.current = new StreamingAvatar({ token: newToken })
    avatar.current.on(StreamingEvents.AVATAR_START_TALKING, (e) => {
      console.log('Avatar started talking', e)
    })
    avatar.current.on(StreamingEvents.AVATAR_STOP_TALKING, (e) => {
      console.log('Avatar stopped talking', e)
    })
    avatar.current.on(StreamingEvents.STREAM_DISCONNECTED, () => {
      console.log('Stream disconnected')
      endSession()
    })
    avatar.current?.on(StreamingEvents.STREAM_READY, (event) => {
      console.log('>>>>> Stream ready:', event.detail)
      setStream(event.detail)
    })
    avatar.current?.on(StreamingEvents.USER_START, (event) => {
      console.log('>>>>> User started talking:', event)
      setIsUserTalking(true)
    })
    avatar.current?.on(StreamingEvents.USER_STOP, (event) => {
      console.log('>>>>> User stopped talking:', event)
      setIsUserTalking(false)
    })

    try {
      const response = await avatar.current.createStartAvatar({
        quality: AvatarQuality.High,
        avatarName: avatarId,
        knowledgeId: '', // Or use a custom `knowledgeBase`.
        voice: {
          //          rate: 1.5, // 0.5 ~ 1.5
          emotion: VoiceEmotion.SERIOUS,
          voiceId: '32449d478a5c4292b1e8775e6224c710',
        },
        language: 'en',
        disableIdleTimeout: false,
      })

      setData(response)
      toggleLoadingVIdeo(true)
      // Use the functional form of setState to ensure the correct state is set
      setIsSessionActive((prev) => {
        if (!prev) {
          console.log('Session activated!') // Debugging log
          return true
        }
        return prev
      })
      //await startRecording()
    } catch (error) {
      console.error('Error starting avatar session:', error)
    }
  }

  // Trigger startRecording when `isSessionActive` changes
  useEffect(() => {
    if (isSessionActive) {
      console.log('Session is active. Starting recording...')
      startRecording()
    }
  }, [isSessionActive])

  useEffect(() => {
    if (
      audioStream &&
      socketRef.current &&
      socketRef.current.readyState === WebSocket.OPEN
    ) {
      const mediaRecorder = new MediaRecorder(audioStream)

      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          socketRef.current?.send(event.data)
        }
      }

      mediaRecorder.start(100)

      return () => {
        mediaRecorder.stop()
      }
    }
  }, [audioStream])

  useEffect(() => {
    return () => {
      if (audioStream) {
        console.log('CLEANING UP AUDIO STREAM ON UNMOUNT')
        audioStream.getTracks().forEach((track) => track.stop())
        setAudioStream(null)
      }
    }
  }, [audioStream])

  useEffect(() => {
    if (stream && mediaStream.current) {
      mediaStream.current.srcObject = stream
      mediaStream.current.onloadedmetadata = () => {
        mediaStream.current.play()
        setDebug('Playing')
      }
    }
  }, [mediaStream, stream])

  useEffect(() => {
    const startSession = async () => {
      await initiateConversationBackend()
      await startHeyGen()
      //await startRecording()
    }

    if (initiateSession) {
      startSession()
    } else {
      endSession()
    }

    return () => {
      endSession()
    }
  }, [initiateSession])

  useEffect(() => {
    return () => {
      console.log('Component unmounting. Cleaning up session...')
      endSession() // Make sure everything is closed when component unmounts
    }
  }, [])

  return (
    <div className={styles.container}>
      {stream && (
        <div className={styles.videoContainer}>
          <video
            ref={mediaStream}
            autoPlay
            playsInline
            className={styles.video}
          ></video>
        </div>
      )}
    </div>
  )
}
export default InteractiveAvatar

//   const res = await fetch('https://api.heygen.com/v1/streaming.list', {
//     method: 'GET',
//     headers: {
//       accept: 'application/json',
//       'x-api-key': import.meta.env.VITE_APP_HEYGEN_API_KEY,
//     },
//   })

//   const data2 = await res.json()

//   console.log('Res is: ', data2)

//   const res3 = await fetch('https://api.heygen.com/v1/streaming.stop', {
//     method: 'POST',
//     headers: {
//       accept: 'application/json',
//       'content-type': 'application/json',
//       'x-api-key': import.meta.env.VITE_APP_HEYGEN_API_KEY,
//     },
//     body: JSON.stringify({
//       session_id: '844adb08-b1a4-11ef-93d4-0eb4d364c31c',
//     }),
//   })
