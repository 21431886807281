import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useReducer,
} from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import {
  getUserConversations,
  getChatbot,
} from '../../services/API/ChatServices'
import ChatPanel from './ChatPanel'
import ChatWindow from './ChatWindow'
import styles from './../../styles/newChatting/ChatPage.module.css'
import MenuOpenIcon from '@mui/icons-material/MenuOpen'
import { chatReducer, initialState } from '../../utils/chatReducer'

const ChatPage = ({}) => {
  useEffect(() => {
    console.log('Chat Page - Component mounted')

    return () => {
      console.log('Chat Page - Component will unmount')
    }
  }, [])

  useEffect(() => {
    console.log('Chat Page - Component updated')
  })

  const backgroundImage = `url(${process.env.PUBLIC_URL}/images/linesTransparent.png)`

  const { chatBotId, conversationId } = useParams()
  const navigate = useNavigate()

  const [chatbotDetails, setChatbotDetails] = useState([])

  const [error, setError] = useState('')
  const [information, setInformation] = useState('')

  const [stateC, setStateC] = useState('')

  const [sessionFlowiseID, setSessionFlowiseId] = useState(null)

  const handleSetSessionFlowiseId = (sessionFlowiseID) => {
    setSessionFlowiseId(sessionFlowiseID)

    console.log('CHAT PAGE: SESSION FLOWISE ID IS: ', sessionFlowiseID)
  }

  //Fetch selected chatbot data
  useEffect(() => {
    console.log('CHATBOT ID IS: ', chatBotId)
    console.log('I SHOULD RUN')
    const fetchChatbot = async () => {
      try {
        if (chatBotId) {
          const response = await getChatbot(chatBotId)

          if (response.success) {
            const chatbotData = response.payload
            setChatbotDetails(chatbotData)
            console.log('The chatbot details are: ', chatbotData)
          } else {
            setInformation(response.message)
            //redirect to dashboard
            navigate('/chatbot-dashboard')
          }
        }
      } catch (error) {
        setError('An unexpected error occurred. Please try again.')
        console.log('I have hit catch error')
      }
    }

    fetchChatbot()
  }, [chatBotId])

  const [isPanelVisible, setIsPanelVisible] = useState(false)

  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <div className={styles.box} style={{ backgroundImage }}>
      {windowWidth <= 768 && (
        <MenuOpenIcon
          className={styles.hamburgerIcon}
          onClick={() => setIsPanelVisible(!isPanelVisible)}
        />
      )}
      {windowWidth > 768 || isPanelVisible ? (
        <ChatPanel
          className={styles.firstBox}
          setPanelVisibility={() => setIsPanelVisible(!isPanelVisible)}
          handleSessionFlowiseID={handleSetSessionFlowiseId}
        />
      ) : null}
      {chatbotDetails !== null ? (
        <div className={styles.secondBox}>
          <ChatWindow
            chatbotId={chatBotId}
            chatbotDetails={chatbotDetails}
            sessionFlowiseID={sessionFlowiseID}
          />
          {/* Nested routes will render here */}
        </div>
      ) : (
        <div>LOADING</div>
      )}
    </div>
  )
}
export default ChatPage
