import React, { useState } from 'react'
import { ChevronLeft, ChevronRight, AlertTriangle } from 'lucide-react'
import styles from './CardSlider.module.css'

const sampleImage = process.env.PUBLIC_URL + '/images/testSlider/forest.jpg'
const sampleVideo = process.env.PUBLIC_URL + '/images/testSlider/MVP TEST.mp4'

// Error fallback component
const ErrorContent = ({ message }) => (
  <div className={styles.errorContainer}>
    <AlertTriangle className={styles.errorIcon} />
    <p className={styles.errorMessage}>
      {message || 'Error displaying content'}
    </p>
  </div>
)

// Error boundary to isolate errors
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    console.error('Error in CardContent:', error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      return <ErrorContent message="Failed to load content" />
    }
    return this.props.children
  }
}

// Content type components
const TextContent = ({ text }) => {
  if (!text) {
    return <ErrorContent message="Text content is missing" />
  }
  return <p className={styles.cardContent}>{text}</p>
}

const ImageContent = ({ src, alt, caption }) => {
  const [hasError, setHasError] = useState(false)

  if (hasError) {
    return <ErrorContent message="Image failed to load" />
  }

  return (
    <div className={styles.imageContainer}>
      <img
        src={src}
        alt={alt || 'Image'}
        className={styles.cardImage}
        onError={() => setHasError(true)}
      />
      {caption && <p className={styles.imageCaption}>{caption}</p>}
    </div>
  )
}

const AudioContent = ({ src, title }) => {
  const [hasError, setHasError] = useState(false)

  if (!src || hasError) {
    return <ErrorContent message="Audio source is missing or failed to load" />
  }

  return (
    <div className={styles.audioContainer}>
      {title && <p className={styles.audioTitle}>{title}</p>}
      <audio
        controls
        className={styles.audioPlayer}
        onError={() => setHasError(true)}
      >
        <source src={src} type="audio/mpeg" />
        <ErrorContent message="Audio playback is not supported" />
      </audio>
    </div>
  )
}

const VideoContent = ({ src, title }) => {
  const [hasError, setHasError] = useState(false)

  if (!src || hasError) {
    return <ErrorContent message="Video source is missing or failed to load" />
  }

  return (
    <div className={styles.videoContainer}>
      <video
        controls
        className={styles.videoPlayer}
        onError={() => setHasError(true)}
      >
        <source src={src} type="video/mp4" />
        <ErrorContent message="Video playback is not supported" />
      </video>
    </div>
  )
}

const CardContent = ({ content }) => {
  if (!content || typeof content !== 'object') {
    return <ErrorContent message="Invalid content data" />
  }

  try {
    switch (content.type) {
      case 'text':
        return <TextContent text={content.text} />
      case 'image':
        return <ImageContent {...content} />
      case 'audio':
        return <AudioContent {...content} />
      case 'video':
        return <VideoContent {...content} />
      case 'mixed':
        if (!Array.isArray(content.elements) || content.elements.length === 0) {
          return <ErrorContent message="Invalid mixed content" />
        }
        return (
          <div className={styles.mixedContent}>
            {content.elements.map((element, index) => (
              <ErrorBoundary key={index}>
                <CardContent content={element} />
              </ErrorBoundary>
            ))}
          </div>
        )
      default:
        return <ErrorContent message="Unsupported content type" />
    }
  } catch (error) {
    console.error('Error rendering card content:', error)
    return <ErrorContent message="Failed to display content" />
  }
}

const CardSlider = () => {
  const cards = [
    {
      id: 1,
      title: 'Mountains',
      content: {
        type: 'text',
        text: 'The mighty chunks rise all over the world, including the oceans. They usually have steep, sloping sides and sharp or rounded ridges, and a high point, called a peak or summit. Most geologists classify a mountain as a landform that rises at least 1,000 feet (300 meters) or more above its surrounding area. A mountain range is a series or chain of mountains that are close together.',
      },
    },
    {
      id: 2,
      title: 'Forest',
      content: {
        type: 'mixed',
        elements: [
          {
            type: 'text',
            text: 'The forest lay before me, dark and foreboding. The trees towered above, their branches twisting and gnarled like grasping fingers. A thick carpet of leaves muffled my footsteps as I made my way deeper into the woods.The air was thick with the scent of decaying leaves and damp earth, and I could not shake the feeling that I was being watched. Every rustle of leaves, every creaking branch made my heart race with fear.',
          },
          { type: 'image', src: sampleImage, alt: 'Forest with lake' },
        ],
      },
    },
    {
      id: 3,
      title: 'Video Card',
      content: {
        type: 'video',
        src: sampleVideo,
      },
    },
  ]

  const [currentIndex, setCurrentIndex] = useState(0)
  const [isAnimating, setIsAnimating] = useState(false)

  const goToNext = () => {
    if (isAnimating) return
    setIsAnimating(true)
    setCurrentIndex((prevIndex) => (prevIndex + 1) % cards.length)
    setTimeout(() => setIsAnimating(false), 300)
  }

  const goToPrev = () => {
    if (isAnimating) return
    setIsAnimating(true)
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? cards.length - 1 : prevIndex - 1,
    )
    setTimeout(() => setIsAnimating(false), 300)
  }

  const renderCard = (index, className) => {
    const card = cards[index]

    if (!card) {
      return (
        <div className={className}>
          <div className={styles.cardInner}>
            <ErrorContent message="Card not found" />
          </div>
        </div>
      )
    }

    // Check if the card is a video to apply specific styling
    const isVideoCard = card.content.type === 'video'

    return (
      <div className={className}>
        <div className={isVideoCard ? styles.videoCardInner : styles.cardInner}>
          {!isVideoCard && (
            <h3 className={styles.cardTitle}>
              {card.title || 'Untitled Card'}
            </h3>
          )}
          <ErrorBoundary>
            <CardContent content={card.content} />
          </ErrorBoundary>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <div className={styles.sliderContainer}>
        <button
          onClick={goToPrev}
          className={styles.prevButton}
          disabled={isAnimating}
        >
          <ChevronLeft />
        </button>
        {renderCard(
          currentIndex === 0 ? cards.length - 1 : currentIndex - 1,
          `${styles.card} ${styles.prevCard}`,
        )}
        {renderCard(currentIndex, `${styles.currentCard}`)}
        {renderCard(
          (currentIndex + 1) % cards.length,
          `${styles.card} ${styles.nextCard}`,
        )}

        <button
          onClick={goToNext}
          className={styles.nextButton}
          disabled={isAnimating}
        >
          <ChevronRight />
        </button>
      </div>
    </div>
  )
}

export default CardSlider
