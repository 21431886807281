import { initialState as defaultState } from './chatReducer'

export const initialState = {
  chatbotFlowiseID: null,
  chatbotFlowID: null,
  isLoading: false,
  conversation: [],
  information: '',
  error: '',
  videoToogle: false,
  chatbotTyping: false,
  fileBase64String: null,
  temporaryFile: null,
  previewImage: null,
  conversationMeta: null,
  dropzoneToogle: false,
  sendButtonToogle: false,
  sendingToChatbot: false,
  userMessage: '',
  textAreaValue: '',
  isVideoStreamingReady: false,
  errorVideo: '',
  currentApiMessageChunk: null,
  currentApiMessageTranscriptFull: null,
  courseJSON: '',
}

export function chatReducer(state, action) {
  switch (action.type) {
    case 'SET_FLOWISE_CHATBOT_ID':
      return { ...state, chatbotFlowiseID: action.payload }
    case 'SET_CHATBOT_FLOW_ID':
      return { ...state, chatbotFlowID: action.payload }
    case 'SET_LOADING':
      return { ...state, isLoading: action.payload }
    case 'SET_CONVERSATION':
      return { ...state, conversation: action.payload }
    case 'SET_INFORMATION':
      return { ...state, information: action.payload }
    case 'SET_ERROR':
      return { ...state, error: action.payload }
    case 'SET_VIDEO_ERROR':
      return { ...state, errorVideo: action.payload }
    case 'SET_VIDEO_TOOGLE':
      return { ...state, videoToogle: action.payload }
    case 'SET_CHATBOT_TYPING':
      return { ...state, chatbotTyping: action.payload }
    case 'SET_CURRENT_API_MESSAGE_TRANSCRIPT_FULL':
      return { ...state, currentApiMessageTranscriptFull: action.payload }
    case 'SET_CURRENT_API_MESSAGE_CHUNK':
      return { ...state, currentApiMessageChunk: action.payload }
    case 'SET_DROPZONE_TOOGLE':
      return { ...state, dropzoneToogle: action.payload }
    case 'SET_SEND_BUTTON_TOGGLE':
      return { ...state, sendButtonToogle: action.payload }
    case 'SET_SENDING_TO_CHATBOT':
      return { ...state, sendingToChatbot: action.payload }
    case 'SET_USER_MESSAGE':
      return { ...state, userMessage: action.payload }
    case 'SET_TEXT_AREA_VALUE':
      return { ...state, textAreaValue: action.payload }
    case 'SET_FILE':
      return {
        ...state,
        fileBase64String: action.payload.fileBase64String,
        temporaryFile: action.payload.temporaryFile,
      }

    case 'SET_VIDEO_STREAMING_READY':
      return { ...state, isVideoStreamingReady: action.payload }

    case 'SET_PREVIEW_IMAGE':
      return { ...state, previewImage: action.payload }

    case 'SET_COURSE_JSON':
      return { ...state, courseJSON: action.payload }

    case 'RESET_STATE':
      return { ...defaultState } // Reset to the initial state

    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}
