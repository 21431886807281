import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styles from '../../styles/MicrolearningCoursePage.module.css'
import CourseSlider from './CourseSlider'

const MicrolearningCoursePage = () => {
  const navigate = useNavigate()
  const { courseId } = useParams()
  const storedCourseData = sessionStorage.getItem('microlearningCourse')
  const parsedCourseData = storedCourseData
    ? JSON.parse(storedCourseData)
    : null

  const [microlearningCourseData, setMicroLearningCourseData] = useState(null)

  useEffect(() => {
    console.log('Stored data in storage is: ', parsedCourseData)
    console.log('Course id in params is: ', courseId)

    if (
      !parsedCourseData ||
      !courseId ||
      parseInt(courseId) !== parsedCourseData.courseID
    ) {
      console.log('It is not matching so redirecting to dashboard')
      navigate('/chatbot-dashboard')
    } else {
      console.log('Setting microlearning course data')
      setMicroLearningCourseData(parsedCourseData)
    }
  }, [courseId, navigate])

  return (
    <div className={styles.container}>
      <p className={styles.title}>
        {microlearningCourseData?.courseJSON?.course?.title || 'Loading...'}
      </p>
      <CourseSlider courseData={microlearningCourseData} />
    </div>
  )
}

export default MicrolearningCoursePage
