import api from './APIconfig'

const titleGeneratorChatbot = process.env.REACT_APP_TITLE_GENERATOR_CHATBOT

// Fetches user chatbots from the server.
// `withToken` is a flag to determine if the request should include an authorization token.
export const getUserChatbots = async (withToken = true) => {
  try {
    // Makes a GET request to '/chat/getUserChatbots' endpoint.
    // The `withToken` flag is passed to include/exclude the authorization token in the request header.
    const response = await api.get('/chat/getUserChatbots', {
      withToken: withToken,
    })
    console.log('Response is: ', response)
    return response.data // Returns the data from the response.
  } catch (error) {
    console.error(error)
    throw error // Propagates the error for further handling.
  }
}

export const registerChatbot = async (chatbotData, withToken = true) => {
  try {
    const response = await api.post('chat/registerChatbot', {
      ...chatbotData,
      withToken: withToken,
    })
    return response.data // Returns the data from the response.
  } catch (error) {
    console.error(error)
    throw error
  }
}

// Fetches conversation titles and IDs for a given chatbot.
// `chatbotId` is the identifier of the chatbot.
// `withToken` determines if the request should include an authorization token.
export const getUserConversations = async (chatbotId, withToken = true) => {
  try {
    // The chatbot ID is appended as a query parameter in the request URL.
    const response = await api.get(
      `/chat/getChatsTitlesAndIds?chatbotId=${chatbotId}`,
      { withToken: withToken },
    )
    return response.data // Returns the fetched data.
  } catch (error) {
    console.error(error)
    throw error
  }
}

// Fetches chat messages for a specific conversation in a chatbot.
// `chatbotId` and `conversationId` are used to identify the specific conversation.
// `withToken` indicates if the request should carry an authorization token.
export const getChatMessages = async (
  chatbotId,
  conversationId,
  withToken = true,
) => {
  try {
    // Makes a GET request with chatbot and conversation IDs as query parameters.
    const response = await api.get(
      `/chat/getChatMessages?chatbotId=${chatbotId}&conversationId=${conversationId}`,
      { withToken: withToken },
    )

    console.log('*******************CHAT MESSAGES BASED ON CONV ID')
    console.log(response.data)
    return response.data // Returns the chat messages.
  } catch (error) {
    console.error(error)
    throw error
  }
}

// Sends a prompt message to a chatbot.
// `data` contains the message content, `url` is the endpoint to send the message, and `withToken` indicates if the token should be included.
export const sendPromptToChatbot = async (data, url, withToken = false) => {
  console.log('Data from service ', data)
  try {
    // The data is converted to a JSON string before sending.
    const dataJSON = JSON.stringify(data)
    // POST request is made to the specified URL with the data.
    // `withCredentials: false` means this request does not include cookies or other credentials.
    const response = await api.post(url, dataJSON, {
      withToken: withToken,
    })

    console.log('Received response from chatbot: ', response.data)
    return response.data // Returns the response data from the server.
  } catch (error) {
    console.error(error)
    throw error
  }
}

// Creates a new chat in a chatbot.
// `chatbotID` is the identifier of the chatbot, `title` is the title of the new chat, and `chatMessages` contains the initial messages.
export const createNewChat = async (formData, withToken = true) => {
  //console.log('CHAT MESSAGES: ', formData)
  try {
    //const body = { chatMessages, tokens: { tokensChat } }
    // POST request to create a new chat. The chatbot ID and title are passed as query parameters.
    const response = await api.post(
      `/chat/createNewChat`,

      formData,

      { withToken: withToken, formData: true },
    )

    console.log('IN THE CHAT SERVICES: createNewChat')
    console.log(response)
    return response // Returns the full response object.
  } catch (error) {
    console.error(error)
    throw error
  }
}

// Updates chat messages for a specific conversation in a chatbot.
// `chatbotID` and `conversationID` identify the conversation, while `chatMessages` contains the updated messages.
export const updateChatMessages = async (formData, withToken = true) => {
  try {
    // POST request to update chat messages with the chatbot and conversation IDs in the query.
    const response = await api.post(`/chat/updateChatMessages`, formData, {
      withToken: withToken,
      formData: true,
    })
    console.log('IN THE CHAT SERVICES: updateChatMessages')
    console.log(response)
    return response // Returns the response from the server.
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const deleteChat = async (conversationID) => {
  try {
    const response = await api.delete(
      `/chat/deleteChat?conversationId=${conversationID}`,
    )
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const getChatbot = async (chatbotId, withToken = true) => {
  try {
    const response = await api.get(`/chat/getChatbot?chatbotId=${chatbotId}`, {
      withToken: withToken,
    })

    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const deleteChatbot = async (chatbotId, withToken = true) => {
  try {
    const response = await api.delete(
      `/chat/deleteChatbot?chatbotId=${chatbotId}`,
      {
        withToken: withToken,
      },
    )
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const updateChatbot = async (
  chatbotData,
  chatbotId,
  withToken = true,
) => {
  try {
    const response = await api.post(
      `chat/updateChatbot?chatbotId=${chatbotId}`,
      {
        ...chatbotData,
        withToken: withToken,
      },
    )
    return response.data // Returns the data from the response.
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const generateTitle = async (message, withToken = false) => {
  //console.log('The url is: ', titleGeneratorChatbot)
  try {
    const dataJSON = JSON.stringify(message)
    const response = await api.post(titleGeneratorChatbot, dataJSON, {
      withToken: withToken,
    })
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const getImage = async (data, withToken = true) => {
  console.log(data)
  try {
    const response = await api.post('chat/getImage', {
      ...data,
      withToken: withToken,
    })
    console.log('IN THE CHAT SERVICES: getImage')
    console.log(response)
    return response
  } catch (error) {
    console.error(error)
    throw error
  }
}
