// ChatbotDashboard.js
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import styles from '../../styles/ChatbotDashboard.module.css'
import { getUserChatbots } from '../../services/API/ChatServices'
//import { setChatbotDataInSession } from '../../utils/SessionStorageUtil'
import ErrorCard from '../ErrorCard'
import InformationCard from '../InformationCard'
import Frame from '../Frame'
import EditNoteIcon from '@mui/icons-material/EditNote'
import DeleteIcon from '@mui/icons-material/Delete'
import { useAuth } from '../../contexts/AuthContext'
import { deleteChatbot } from '../../services/API/ChatServices'
import { pageVariants, pageTransition } from '../../animations/pageTransitions'
import { motion } from 'framer-motion'

const ChatbotDashboardPage = () => {
  // Images for chatbot and information icon
  const chatbotImage = process.env.PUBLIC_URL + '/images/chatbot.png'

  // State management
  const [chatbots, setChatbots] = useState([])
  const [error, setError] = useState('')
  const [information, setInformation] = useState('')
  const [showInformation, setShowInformation] = useState(false)
  const [showErrorDelete, setShowErrorDelete] = useState(false)

  const [microlearningCourses, setMicrolearningCourses] = useState([])

  // Navigation hook for redirecting the user
  const navigate = useNavigate()

  const { user } = useAuth()

  console.log(user)

  // Function to handle selecting a chatbot
  const handleSelectChatbot = (chatbot) => {
    // Setting chatbot data in session storage
    // setChatbotDataInSession({
    //   chatbotID: chatbot.chatbot_id,
    //   chatbotName: chatbot.ChatbotModel.name,
    //   chatbotTitle: chatbot.ChatbotModel.title,
    //   chatbotDescription: chatbot.ChatbotModel.description,
    //   chatbotURL: chatbot.ChatbotModel.url,
    //   chatbotAvatar: chatbot.ChatbotModel.avatar,
    // })

    const chatbotDataSession = {
      chatbotID: chatbot.chatbot_id,
      chatbotName: chatbot.ChatbotModel.name,
      chatbotTitle: chatbot.ChatbotModel.title,
      chatbotDescription: chatbot.ChatbotModel.description,
      chatbotURL: chatbot.ChatbotModel.url,
      chatbotAvatar: chatbot.ChatbotModel.avatar,
    }
    // Navigating to chatbot interface
    navigate(`/chatbot-dashboard/${chatbot.chatbot_id}/new-conversation`, {
      state: chatbotDataSession,
    })
  }

  const handleSelectMicrolearningCourse = (course) => {
    const microlearningCourseDataSession = {
      courseID: course.id,
      courseTitle: course.title,
      courseDescription: course.description,
      courseJSON: course.json,
      video_1_url: course.video_1_url,
      video_2_url: course.video_2_url,
      video_3_url: course.video_3_url,
    }

    sessionStorage.setItem(
      'microlearningCourse',
      JSON.stringify(microlearningCourseDataSession),
    )
    console.log(
      'before saving to local storage: ',
      microlearningCourseDataSession,
    )
    navigate(`/chatbot-dashboard/microlearning-course/${course.id}`)
  }

  // Fetching user chatbots on component mount
  useEffect(() => {
    const fetchUserChatbots = async () => {
      // try {
      //   const response = await getUserChatbots()
      //   console.log('Response from GET USER CHTBOTS: ', response)
      //   if (response.success) {
      //     // Setting chatbots state if fetch is successful
      //     console.log('Res chatbots is: ', response.payload)
      //     const chatbotsArray = orginizeChatbotsByType(response.payload)
      //     console.log('Chatbots Array new is: ', chatbotsArray)
      //     setChatbots(chatbotsArray)
      //   } else if (
      //     !response.success &&
      //     response.message === 'No chatbots found'
      //   ) {
      //     // Handling the case where no chatbots are assigned to the user
      //     setInformation(
      //       "You currently don't have any chatbot assistants assigned. To get started, please reach out to the person in charge of chatbot assignments in your organization. If you have any questions or need further assistance, feel free to contact our support team. We're here to help you every step of the way.",
      //     )
      //     setShowInformation(true)
      //   }
      // }

      try {
        const response = await getUserChatbots()
        if (response.result.success) {
          const chatbotsArray = orginizeChatbotsByType(response.result.payload)
          setChatbots(chatbotsArray)
        } else if (!response.result.success) {
          setInformation(
            "You currently don't have any chatbot assistants assigned. To get started, please reach out to the person in charge of chatbot assignments in your organization. If you have any questions or need further assistance, feel free to contact our support team. We're here to help you every step of the way.",
          )
        }
        if (response.result2.success) {
          //loop through and parse json for each field
          const courses = setMicrolearningCoursesFromResponse(
            response.result2.payload,
          )
          setMicrolearningCourses(courses)
          console.log('Coures are: ', courses)
        } else if (!response.result2.success) {
        }
      } catch (error) {
        // Handling errors during data fetch
        console.error('Error fetching chatbots:', error)
        setError('An unexpected error occurred. Please try again.')
      }
    }

    fetchUserChatbots()
  }, [])

  const orginizeChatbotsByType = (chatbotsArray) => {
    return chatbotsArray.reduce((acc, chatbot) => {
      const type = chatbot.ChatbotModel.ChatbotTypeModel?.type || 'uknown'
      console.log('Type is: ', type)
      if (!acc[type]) {
        acc[type] = []
      }
      acc[type].push(chatbot)
      return acc
    }, [])
  }

  const setMicrolearningCoursesFromResponse = (res) => {
    console.log('Res is: ', res)
    if (!Array.isArray(res)) {
      console.error('Invalid result2 format or no microlearning courses found.')
      return []
    }

    // Transform the payload into an array of structured objects
    const microlearningCourses = res.map((course) => {
      return {
        id: course.MicrolearningCourseModel.id,
        type:
          course.MicrolearningCourseModel.ChatbotTypeModel?.type || 'unknown', // Handle missing type
        json: course.MicrolearningCourseModel.json
          ? JSON.parse(course.MicrolearningCourseModel.json)
          : {}, // Parse JSON safely
        video_1_url: course.MicrolearningCourseModel.video_1_url || null,
        video_2_url: course.MicrolearningCourseModel.video_2_url || null,
        video_3_url: course.MicrolearningCourseModel.video_3_url || null,
      }
    })

    console.log('Formatted Microlearning Courses:', microlearningCourses)
    return microlearningCourses
  }

  // Function to hide information card
  const handleInformationVisibility = () => {
    setShowInformation(false)
  }

  const handleErrorDeleteVisibility = () => {
    setShowErrorDelete(false)
  }

  const isCompanyAdmin = user.role === 'company_admin'

  const handleDeleteChatbot = async (chatbotId) => {
    try {
      const response = await deleteChatbot(chatbotId)
      if (
        response.success &&
        response.message === 'Chatbot deleted successfully'
      ) {
        //chatbot deleted successfully
        // Filter out the deleted chatbot from the chatbots array
        const updatedChatbots = chatbots.filter(
          (bot) => bot.chatbot_id !== chatbotId,
        )
        setChatbots(updatedChatbots) // Update the state with the new array
        console.log('Chatbot deleted successfully')
      } else {
        //problem
        setShowErrorDelete(true)
      }
    } catch (error) {
      setShowErrorDelete(true)
    }
  }

  const squashMenuItems = [
    {
      id: 1,
      label: 'Edit',
      icon: <EditNoteIcon className={styles.icon} />,
      action: (chatbotId) => {
        const chatbotToEdit = chatbots.find(
          (bot) => bot.chatbot_id === chatbotId,
        )

        if (chatbotToEdit) {
          navigate('/update-chatbot', {
            state: { chatbot: chatbotToEdit },
          })
        }
      },
    },
    {
      id: 2,
      label: 'Delete',
      icon: <DeleteIcon className={styles.icon} />,
      action: (chatbotId) => {
        handleDeleteChatbot(chatbotId)
      },
    },
  ]

  // Rendering logic based on different states
  return (
    <motion.div
      style={{ width: '100%', height: '100%' }}
      variants={pageVariants}
      initial="initial"
      animate="in"
      exit="out"
      transition={pageTransition}
    >
      <div className={styles.container}>
        {error ? (
          <ErrorCard message={error} action="RELOAD" name="Retry" />
        ) : showInformation ? (
          <InformationCard
            title="Notice"
            information={information}
            toggleInformationVisibility={handleInformationVisibility}
            imagePath={chatbotImage}
          />
        ) : (
          <>
            {(user.role === 'company_admin' || user.role === 'web_admin') && (
              <>
                <div className={styles.pageTitle}>Microlearning templates</div>
                <hr className={styles.titleLine} />
                <div className={styles.dashboard}>
                  {chatbots.designer ? (
                    chatbots.designer.map((bot) => (
                      <Frame
                        key={bot.chatbot_id}
                        onClick={() => handleSelectChatbot(bot)}
                        data={{
                          name: bot.ChatbotModel.name,
                          title: bot.ChatbotModel.title,
                          description: bot.ChatbotModel.description,
                          avatar: `${process.env.PUBLIC_URL}/images/avatars/${bot.ChatbotModel.avatar}.png`,
                        }}
                        menuItems={isCompanyAdmin ? squashMenuItems : []}
                        chatbotId={bot.chatbot_id}
                      />
                    ))
                  ) : (
                    <p className={styles.text}>
                      {' '}
                      No microlearning templates found
                    </p>
                  )}
                </div>
              </>
            )}
            <div className={styles.pageTitle}>Microlearning Courses</div>
            <hr className={styles.titleLine} />
            <div className={styles.dashboard}>
              {microlearningCourses.length > 0 ? (
                microlearningCourses.map((course) => (
                  <Frame
                    key={course.id}
                    onClick={() => handleSelectMicrolearningCourse(course)}
                    data={{
                      name: '',
                      title: course.json.course.title,
                      description: course.json.course.description,
                      avatar: `${
                        process.env.PUBLIC_URL
                      }/images/avatars/${'BeeCircle'}.png`,
                    }}
                    menuItems={isCompanyAdmin ? squashMenuItems : []}
                    chatbotId={course.id}
                  />
                ))
              ) : (
                <p className={styles.text}>No microlearning courses found</p>
              )}
            </div>
            <div className={styles.pageTitle}>General Knowledge Assistants</div>
            <hr className={styles.titleLine} />
            <div className={styles.dashboard}>
              {chatbots.general &&
                chatbots.general.map((bot) => (
                  <Frame
                    key={bot.chatbot_id}
                    onClick={() => handleSelectChatbot(bot)}
                    data={{
                      name: bot.ChatbotModel.name,
                      title: bot.ChatbotModel.title,
                      description: bot.ChatbotModel.description,
                      avatar: `${process.env.PUBLIC_URL}/images/avatars/${bot.ChatbotModel.avatar}.png`,
                    }}
                    menuItems={isCompanyAdmin ? squashMenuItems : []}
                    chatbotId={bot.chatbot_id}
                  />
                ))}
            </div>
          </>
        )}
        {showErrorDelete && (
          <InformationCard
            title="Notice"
            information="There was a problem to delete the chatbot."
            toggleInformationVisibility={handleErrorDeleteVisibility}
            imagePath={chatbotImage}
          />
        )}
      </div>
    </motion.div>
  )
}

export default ChatbotDashboardPage
