import { useState } from 'react'
import styles from './TheoryChatbotRetrival.module.css'
import axios from 'axios'

const TheoryChatbotRetrival = () => {
  const HEYGEN_API_KEY =
    'MTEwMjg3OGEyY2RhNDcxZmJmOWY5MWRmYmU1MzI4YzItMTczNDAxODMwNg==' // Replace with actual API key
  const HEYGEN_API_URL = 'https://api.heygen.com/v2/video/generate'
  const HEYGEN_STATUS_URL = 'https://api.heygen.com/v1/video_status.get'
  const chatbotURl =
    'https://chatbot.humanasset.com/api/v1/prediction/4ff88127-2b69-4837-940b-383b103f4300'

  const sampleImage = process.env.PUBLIC_URL + '/images/testSlider/forest.jpg'

  const FLOWISE_API_KEY = 'Rm7oE3JpJRzCvFEg6Obwz3y5Ay8SpBhGrxXceY2mYng'

  //apiKey: 'Rm7oE3JpJRzCvFEg6Obwz3y5Ay8SpBhGrxXceY2mYng',

  const [userPrompt, setUserPrompt] = useState('')
  const [sendButtonDisabled, setSendButtonDisabled] = useState(false)
  const [theoryCards, setTheoryCards] = useState(null)

  const handleUserPromptChange = (event) => {
    const userPrompt = event.target.value
    setUserPrompt(userPrompt.trim())
    const emptyUserPrompt = userPrompt.trim() === '' || userPrompt === null

    setSendButtonDisabled(emptyUserPrompt)
  }

  const handleSubmitTheory = async () => {
    console.log('I was pressed')
    setSendButtonDisabled(true)
    const data = { question: userPrompt }
    try {
      const response = await axios.post(chatbotURl, data, {
        headers: {
          Authorization: 'Bearer Rm7oE3JpJRzCvFEg6Obwz3y5Ay8SpBhGrxXceY2mYng',
          'Content-Type': 'application/json',
        },
      })

      if (response.status === 200) {
        console.log(response)
        const fixedCards = fixReturnedCards(response.data.text)
        setTheoryCards(fixedCards)
        // Check if there is a video card
        const videoCard = fixedCards.find(
          (card) => card.content.type === 'video',
        )
        if (videoCard) {
          const scriptText = videoCard.content.script
          if (scriptText) {
            console.log('Sending script to HeyGen:', scriptText)
            // Step 1: Request video generation
            const videoId = await sendToHeyGen(scriptText)

            if (videoId) {
              console.log('Video id is: ', videoId)
              // Step 2: Poll until video is ready
              const videoUrl = await checkVideoStatus(videoId)

              if (videoUrl) {
                console.log('Final video URL:', videoUrl)
                alert(`Your video is ready: ${videoUrl}`) // Notify user
              }
            }
          }
        }
        setUserPrompt('')
      } else {
        console.log('There was an error generating the the theory sample')
      }
    } catch (error) {
      console.log('There was an error submiting your text')
    }
  }

  const fixReturnedCards = (responseText) => {
    try {
      const jsonString = responseText
        .replace(/```json/g, '')
        .replace(/```/g, '')

      const parsedData = JSON.parse(jsonString)

      console.log('Parsed data is: ', parsedData)

      if (!parsedData.cards || !Array.isArray(parsedData.cards)) {
        throw new Error('INVALID DATA STRUCTURE')
      }

      parsedData.cards = parsedData.cards.map((card) => {
        if (card.content.type === 'mixed') {
          card.content.elements = card.content.elements.map((element) => {
            if (element.type === 'image' && element.src === null) {
              element.src = sampleImage
            }
            return element
          })
        }
        return card
      })
      return parsedData.cards // Return the corrected array
    } catch (error) {
      console.error('Error parsing or fixing returned cards:', error)
      return []
    }
  }

  const sendToHeyGen = async (scriptText) => {
    try {
      const requestBody = {
        caption: true, // Enable captions
        dimension: { width: 720, height: 1280 }, // Portrait mode
        video_inputs: [
          {
            character: {
              type: 'talking_photo',
              talking_photo_id: '47dda8bd69f44fd19ba2d2a474b20b0e',
            },
            voice: {
              type: 'text',
              input_text: scriptText,
              voice_id: '32449d478a5c4292b1e8775e6224c710',
              emotion: 'Serious',
            },
            background: {
              type: 'color',
              value: '#000000', // Black background
            },
          },
        ],
      }

      const response = await axios.post(HEYGEN_API_URL, requestBody, {
        headers: {
          'X-Api-Key': HEYGEN_API_KEY,
          'Content-Type': 'application/json',
        },
      })

      if (response.data && response.data.data && response.data.data.video_id) {
        console.log(
          'Video Generation Started, Video ID:',
          response.data.video_id,
        )
        return response.data.data.video_id
      } else {
        console.error('Failed to start video generation', response.data)
        return null
      }
    } catch (error) {
      console.error('Error sending request to HeyGen:', error)
      return null
    }
  }

  const checkVideoStatus = async (videoId) => {
    const MAX_RETRIES = 100 // Maximum attempts
    const INTERVAL_MS = 10000 // Polling interval (5 seconds)

    for (let attempt = 0; attempt < MAX_RETRIES; attempt++) {
      try {
        const response = await axios.get(
          `${HEYGEN_STATUS_URL}?video_id=${videoId}`,
          {
            headers: { 'X-Api-Key': HEYGEN_API_KEY },
          },
        )

        console.log('Video Status:', response.data)

        if (response.data.data.status === 'completed') {
          const videoUrl = response.data.data.video_url
          console.log('Video is ready:', videoUrl)

          // Trigger download
          //downloadVideo(videoUrl)

          return videoUrl
        } else if (response.data.data.status === 'failed') {
          console.error('Video generation failed!')
          return null
        }
      } catch (error) {
        console.error('Error checking video status:', error)
      }

      await new Promise((resolve) => setTimeout(resolve, INTERVAL_MS)) // Wait before retrying
    }

    console.warn('Max retries reached. Video generation is still not complete.')
    return null
  }
  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Theory Retrival Sample</h2>
      <textarea
        value={userPrompt}
        className={styles.inputContainer}
        placeholder="Enter theory text and the required structure returned"
        onChange={handleUserPromptChange}
      ></textarea>
      <button disabled={sendButtonDisabled} onClick={handleSubmitTheory}>
        SUBMIT
      </button>
    </div>
  )
}
export default TheoryChatbotRetrival

// const requestBody = {
//   caption: true,
//   dimension: { width: 720, height: 1280 },
//   video_inputs: [
//     {
//       character: {
//         type: 'avatar',
//         avatar_id: '18db7d323f324629b11335dad1835972',
//         avatar_style: 'normal',
//       },
//       voice: {
//         type: 'text',
//         input_text: scriptText,
//         voice_id: '32449d478a5c4292b1e8775e6224c710',
//         emotion: 'Serious',
//       },
//       background: {
//         type: 'color',
//         value: '#000000',
//       },
//     },
//   ],
// }
